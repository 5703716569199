define('slate-payroll/components/census/table-cell', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks', 'slate-payroll/mixins/controller-abstractions/add-edit'], function (exports, _uxAjaxStatusHooks, _addEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var CensusTableCellComponent = Ember.Component.extend(_addEdit.default, _uxAjaxStatusHooks.default, {
    classNameBindings: ['highlightCell:census__highlight-cell'],
    tagName: 'td',
    guessedField: Ember.computed('availableFields', 'guesses', 'columnIndex', function () {
      return this.get('availableFields').findBy('path', this.get('guesses')[this.get('columnIndex')]);
    }),

    missingRelationship: Ember.computed.reads('validation.missingRelationship'),
    missingRequiredFields: Ember.computed.reads('validation.isRequired'),
    highlightCell: Ember.computed.or('missingRelationship', 'missingRequiredFields', 'hasEnumInvalidation'),

    hasEnumInvalidation: Ember.computed('guessedField.enums.[]', function () {
      var guessedField = this.get('guessedField');

      if (!guessedField || !guessedField.enums) {
        return false;
      }

      var enums = guessedField.enums,
          enumStr = [].concat(_toConsumableArray(enums), [enums.indexOf(null) > -1 ? 'or leave this field blank' : null]).filter(Boolean).join(', ');

      var matchingEnum = enums.includes(this.get('column'));

      return matchingEnum ? false : 'Please use one of these: ' + enumStr;
    }),

    popupMessage: Ember.computed('missingRelationship', function () {
      var relationship = this.get('missingRelationship');

      if (relationship === 'department' || relationship === 'location') {
        return Ember.String.htmlSafe('Could not find this ' + relationship + ',  click to create.');
      }

      return Ember.String.htmlSafe('Could not find this ' + relationship + '.');
    }),

    actions: {
      addAction: function addAction() {
        var _this = this;

        var field = this.get('guessedField.path'),
            column = this.get('column');

        field = field.charAt(0).toUpperCase() + field.slice(1);

        this.addRelationship(field, column).then(function (newRelationshipModel) {
          return _this.send('save', newRelationshipModel);
        }).then(function () {
          return _this.get('onRefresh')();
        });
      }
    }
  });

  CensusTableCellComponent.reopenClass({ positionalParams: ['column', 'rowIndex', 'columnIndex', 'potentialData', 'availableFields', 'guesses', 'validation'] });

  exports.default = CensusTableCellComponent;
});