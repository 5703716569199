define('slate-payroll/components/display/ui-feedback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UIFeedbackComponent = Ember.Component.extend({
    tagName: 'p',
    classNames: ['text-center'],
    classNameBindings: ['errorMessage:text-danger:text-success'],
    errorIcon: 'times-circle',
    successIcon: 'check'
  });

  UIFeedbackComponent.reopenClass({ positionalParams: ['errorMessage', 'successMessage'] });

  exports.default = UIFeedbackComponent;
});