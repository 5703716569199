define('slate-payroll/components/change-request-value-translator', ['exports', 'slate-payroll/models/employee', 'moment'], function (exports, _employee, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'span',

    init: function init() {
      this._super.apply(this, arguments);
      this.displayMap = {
        company: 'name',
        location: 'name',
        department: 'name',
        employee: 'fullName',
        'company-paycode': 'description'
      };
    },


    isRelationship: Ember.computed.bool('coercionType.model'),

    displayKey: Ember.computed('displayMap', 'coercionType.model', function () {
      var model = this.get('coercionType.model');
      return model ? this.get('displayMap.' + model) : undefined;
    }),

    coercionType: Ember.computed(function () {
      var _this = this;

      var attributeKinds = Ember.get(_employee.default, 'fields'),
          changed = this.get('pathForChange'),
          transformedAttrs = Ember.get(_employee.default, 'transformedAttributes');

      var thing = void 0;

      attributeKinds.forEach(function (type, field) {
        if (changed === field) {
          var transform = transformedAttrs.get(field);
          thing = {
            type: type,
            transform: transform
          };
          if (thing.type === 'belongsTo') {
            thing.model = Ember.get(_employee.default.typeForRelationship(field, _this.get('store')), 'modelName');
          }
        }
      });

      return thing;
    }),

    pathForChange: Ember.computed('key', function () {
      var changed = this.get('key');
      return changed && changed.join('.');
    }),

    isObject: Ember.computed('value', function () {
      var val = this.get('value');
      return val && (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object' && Object.keys(val).length > 0;
    }),

    computedValue: Ember.computed('coercionType', 'pathForChange', function () {
      var _getProperties = this.getProperties('value', 'coercionType'),
          value = _getProperties.value,
          coercionType = _getProperties.coercionType;

      if (!coercionType || !value) {
        return value;
      }
      if (coercionType.transform === 'date') {
        return (0, _moment.default)(value).format('MM/DD/YYYY');
      } else if (coercionType.type === 'belongsTo') {
        return this.get('store').findRecord(coercionType.model, value);
      } else {
        return value;
      }
    })
  });
});