define('slate-payroll/components/data-explorer/query-result-value', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var QueryResultValueComponent = Ember.Component.extend({
    time: Ember.computed('value', 'column.name', function () {
      var hideHours = ['rehireDate', 'terminatedOn', 'hireDate', 'birthDate'];

      var column = this.get('column.name');
      var value = this.get('value');

      return hideHours.includes(column) ? (0, _moment.default)(value).format('MM/DD/YY') : (0, _moment.default)(value).format('MM/DD/YY h:mm:ssa');
    }),

    isDate: Ember.computed('value', function () {
      var value = this.get('value');
      return value ? (0, _moment.default)(value, _moment.default.ISO_8601, true).isValid() : false;
    }),

    isArray: Ember.computed('value', function () {
      return Ember.isArray(this.get('value'));
    }),

    isObject: Ember.computed('isArray', 'value', function () {
      return !this.get('isArray') && _typeof(this.get('value')) === 'object';
    }),

    imageDetected: Ember.computed('value', function () {
      var value = this.get('value');
      return value && typeof value === 'string' ? value.indexOf('data:image/png;base64') > -1 : false;
    })
  });

  QueryResultValueComponent.reopenClass({ positionalParams: ['value'] });

  exports.default = QueryResultValueComponent;
});