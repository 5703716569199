define('slate-payroll/components/data-explorer/group-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var groupings = ['Work Week', 'Hour', 'Day', 'Week', 'Month', 'Year'];
  var groupTypes = ['Time', 'Field'];

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.get('grouping.value')) {
        this.set('enableGrouping', true);
        this.set('groupType', this.get('grouping.groupType'));
        if (this.timeGrouping) {
          this.set('groupByTime', this.get('grouping.value'));
        } else {
          var field = this.availableGroupPoints.filter(function (point) {
            return point.field === _this.get('grouping.value');
          })[0];
          this.set('groupField', field);
          var displayBy = this.get('grouping.displayBy') && (this.availableDeepGroupPoints || []).length ? this.availableDeepGroupPoints.filter(function (point) {
            return point.field === _this.get('grouping.displayBy');
          })[0] : false;
          this.set('groupFieldDisplay', displayBy);
        }
      } else {
        this.set('newGrouping', true);
        this.setDefaultGrouping();
        this.setGrouping();
      }
    },


    groupings: groupings,
    groupTypes: groupTypes,
    groupType: 'Time',
    timeGrouping: Ember.computed.equal('groupType', 'Time'),

    groupByTimes: Ember.computed('groupByTime', 'grouping.value', function () {
      return this.get('groupByTime');
    }),

    setGrouping: Ember.observer('groupField', 'groupType', 'groupByTime', 'enableGrouping', 'groupFieldDisplay', function () {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var update;

        if (_this2.get('enableGrouping') && _this2.get('newGrouping')) {
          update = {
            groupType: _this2.get('groupType'),
            value: _this2.get('timeGrouping') ? _this2.get('groupByTime') : _this2.get('groupField.field'),
            displayBy: false
          };
        }

        _this2.set('grouping', !_this2.get('grouping') ? update : _this2.get('grouping'));

        if (_this2.get('groupFieldDisplay.field')) {
          _this2.grouping.displayBy = _this2.get('groupFieldDisplay.field');
        }
      });
    }),

    setDefaultGrouping: function setDefaultGrouping() {
      this.setProperties({
        groupByTime: groupings[0],
        groupField: this.get('availableGroupPoints')[0],
        groupFieldDisplay: (this.get('availableDeepGroupPoints') || []).length ? this.get('availableDeepGroupPoints')[0] : false
      });
    },

    updateValue: Ember.observer('grouping', 'enableGrouping', function () {
      this.set('value', this.get('enableGrouping') ? this.get('grouping') : null);
    }),

    availableGroupPoints: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        if (kind === 'hasMany') {
          return;
        }
        var fieldData = {
          field: field,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
        };

        fields.addObject(fieldData);
      });

      return fields.sortBy('name');
    }),

    availableUnwinds: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A();
      var Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        if (kind !== 'hasMany') {
          return;
        }

        var fieldData = {
          field: field,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
        };

        fields.addObject(fieldData);
      });

      fields = fields.sortBy('name');
      fields.unshift({
        name: 'None',
        value: null
      });

      return fields;
    }),

    availableDeepGroupPoints: Ember.computed('modelName', 'groupField', function () {
      var modelName = this.get('groupField.field');
      var mainModel = this.get('modelName');
      var viewFields = ['name', 'email', 'firstName', 'lastName', 'created', 'finalizedOn', 'paidOn', 'end', 'start', 'closedOn', 'type', ' description', 'code', 'time_stamp', 'exempt'];
      if (!modelName) {
        return false;
      }
      var Main = this.get('store').modelFor(mainModel);
      var rel = Ember.get(Main.typeForRelationship(modelName, this.store) || {}, 'modelName');
      if (!rel) {
        return;
      }
      var Model = void 0;
      try {
        Model = this.get('store').modelFor(rel);
      } catch (e) {
        return false;
      }

      var fields = Ember.A(),
          modelFields = Ember.get(Model, 'fields');
      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        var fieldData = {
          field: field,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' ')),
          relationshipModel: rel
        };
        if (viewFields.includes(field)) {
          fields.addObject(fieldData);
        }
      });

      return fields.sortBy('name');
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      clearGrouping: function clearGrouping() {
        if (!this.get('grouping.value') && this.newQuery) {
          return;
        }
        this.onChange(!this.get('enableGrouping') ? false : {
          groupType: this.get('groupType'),
          value: this.get('timeGrouping') ? this.get('groupByTime') : this.get('groupField.field'),
          displayBy: this.get('groupFieldDisplay.field') || false
        });
      },
      onChangeUnwind: function onChangeUnwind(val) {
        this.get('onChangeUnwind')(val);
      }
    }
  });
});