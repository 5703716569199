define('slate-payroll/components/data-explorer/data-point-selector', ['exports', 'slate-payroll/config/statics'], function (exports, _statics) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['data-explorer-selector'],

    availableDataPoints: Ember.computed('modelName', function () {
      var _this = this;

      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelTransformedAttrs = Ember.get(Model, 'transformedAttributes'),
          modelFields = Ember.get(Model, 'fields');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format
      modelFields.forEach(function (kind, field) {
        if (kind === 'hasMany') {
          return;
        }
        var fieldData = {
          field: field,
          kind: kind,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' ')),
          isRelationship: kind !== 'attribute'
        };

        if (fieldData.isRelationship) {
          fieldData.relationshipModel = Ember.get(Model.typeForRelationship(field, _this.store), 'modelName');
        }

        fields.addObject(fieldData);
      });

      modelTransformedAttrs.forEach(function (kind, field) {
        var matchingField = fields.findBy('field', field);

        if (matchingField) {
          matchingField['is' + Ember.String.capitalize(kind)] = true;
          matchingField.type = kind;
        }
      });

      return fields;
    }),

    availableOperators: Ember.computed('selectedDataPoint', function () {
      var dataPoint = this.get('selectedDataPoint');

      if (!dataPoint) {
        return false;
      }

      var mapKey = dataPoint.isRelationship ? dataPoint.kind : dataPoint.type;

      return Ember.A(_statics.dataTypeOperatorMap[mapKey]);
    }),

    hideField: Ember.computed.equal('selectedOperator', 'doesn\'t exist'),

    availableOperatorsChanged: Ember.observer('availableOperators', function () {
      this.set('selectedOperator', this.get('availableOperators.firstObject'));
    }),

    dataPointObject: Ember.computed('selectedDataPoint', 'selectedOperator', 'dataPointValue.[]', 'enableParameter', function () {
      var point = this.get('selectedDataPoint'),
          operator = this.get('selectedOperator'),
          value = this.hideField ? ' ' : this.get('dataPointValue'),
          valueSet = !Ember.isEmpty(value);

      return point && operator && valueSet ? {
        operator: operator,
        value: value,
        parameter: this.get('enableParameter'),
        isRelationship: point.isRelationship,
        relationshipModel: point.relationshipModel,
        isDate: point.isDate,
        propertyName: point.name,
        propertyKey: point.field,
        propertyType: point.type || point.kind
      } : undefined;
    }),

    actions: {
      addDataPoint: function addDataPoint() {
        var callback = this.get('onDataPoint');

        var dataPointRecord = this.store.createRecord('data-point', this.get('dataPointObject'));
        callback(dataPointRecord);

        this.setProperties({
          selectedDataPoint: null,
          selectedOperator: null,
          dataPointValue: null,
          enableParameter: null
        });
      }
    }
  });
});