define('slate-payroll/components/data-explorer/lazy-model-select', ['exports', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LazyModelSelectComponent = Ember.Component.extend(_uxAjaxStatusHooks.default, {
    store: Ember.inject.service(),
    classNames: ['lazy-model-multi-select-component'],
    classNameBindings: ['multi:lazy-model-multi-select'],
    _modelName: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.get('_modelName') !== this.get('modelName')) {
        this.set('_modelName', this.get('modelName'));
        this.resolveModel().then(function (val) {
          _this.set('resolvedModel', val);
        });
      }
    },
    resolveModel: function resolveModel() {
      var _this2 = this;

      var select = 'id',
          selectors = ['optionLabelPath', 'optionValuePath', 'selectQuery'];

      this.ajaxStart();

      selectors.forEach(function (selector) {
        if (_this2.get(selector)) {
          select += ' ' + _this2.get(selector);
        }
      });

      var fullQuery = { select: select };

      if (this.get('query')) {
        Object.assign(fullQuery, this.get('query'));
      }

      return this.store.query(this.get('modelName'), fullQuery).then(function (results) {
        _this2.ajaxSuccess();
        return results;
      }).catch(this.ajaxError.bind(this));
    },
    updateValue: function updateValue(val) {
      this.set('selection', val);
      this.get('onChange')(val);
    }
  });
  // import { observer, get } from '@ember/object';


  LazyModelSelectComponent.reopenClass({ positionalParams: ['modelName'] });

  /*
    {{data-explorer/lazy-model-multi-select
      'my-model'
      selectId='my-select-id'
      optionLabelPath='model.label.path'
      onChange=(action (mut value))}}
  */

  exports.default = LazyModelSelectComponent;
});