define('slate-payroll/components/data-explorer/display-settings-field-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    draggable: true,
    attributeBindings: ['draggable'],
    classNames: ['data-explorer-drag-and-drop-field'],

    dragStart: function dragStart(event) {
      event.dataTransfer.effectAllowed = 'copy';
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.setData('fieldIndex', this.get('index'));
      this.set('field.dragging', true);
    },
    dragEnd: function dragEnd() {
      var _this = this;

      // Queue up the undrag set, prevents issues when dragging and not dropping
      // on drop location that does this action.
      Ember.run.later(function () {
        _this.set('field.dragging', true);
      }, 100);
    }
  });
});