define('slate-payroll/components/census/table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isInvalid:census__highlight-row'],

    isInvalid: Ember.computed('validation.[]', function () {
      var v = this.get('validation');
      return v && v.some(function () {
        var cell = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        return cell.invalid;
      });
    })
  });
});