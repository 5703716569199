define('slate-payroll/components/display/tool-tip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ToolTipComponent = Ember.Component.extend({
    tooltipOptions: {},

    didInsertElement: function didInsertElement() {
      var opts = this.get('tooltipOptions');

      this.$().tooltip(Object.assign({
        placement: 'top',
        container: 'body',
        html: false,
        title: this.get('title')
      }, opts));

      this.set('__tooltipInitialized', true);
    }
  });

  ToolTipComponent.reopenClass({ positionalParams: ['title'] });

  exports.default = ToolTipComponent;
});