define('slate-payroll/components/close-audit-subapproval-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuditSubapprovalItemComponent = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['isApproved:success', 'lastChild:pas__child-row--last'],

    isApproved: Ember.computed.or('subapproval.at', 'approval.completedOn'),
    lastChild: Ember.computed('index', 'total', function () {
      return this.get('index') + 1 === this.get('total');
    })
  });

  AuditSubapprovalItemComponent.reopenClass({ positionalParams: ['subapproval', 'approval'] });

  exports.default = AuditSubapprovalItemComponent;
});