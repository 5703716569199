define('slate-payroll/components/data-explorer/data-point-group', ['exports', 'slate-payroll/mixins/render-tooltips'], function (exports, _renderTooltips) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_renderTooltips.default, {
    classNames: ['data-point-group'],
    classNameBindings: ['dragHovering:drag-hovering'],

    groupDataPoints: Ember.computed('dataPoints.@each.queryGroup', 'group.id', function () {
      return this.get('dataPoints').filterBy('queryGroup', this.get('group.id'));
    }),

    dragEnter: function dragEnter(event) {
      event.preventDefault();
      this.set('dragHovering', true);
      return false;
    },
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      this.set('dragHovering', false);
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      return false;
    },
    drop: function drop(event) {
      event.preventDefault();
      this.set('dragHovering', false);
      var dataPoints = this.get('dataPoints');
      var dataPointDragging = dataPoints.findBy('dragging', true);

      if (dataPointDragging) {
        dataPointDragging.setProperties({
          queryGroup: this.get('group.id'),
          dragging: false
        });
      }

      return false;
    },


    actions: {
      deleteGroup: function deleteGroup() {
        var callback = this.get('onDelete');
        callback(this.get('group'));
      },
      removeDataPointFromGroup: function removeDataPointFromGroup(dataPoint) {
        dataPoint.set('queryGroup', undefined);
      },
      deleteDataPoint: function deleteDataPoint(dataPoint) {
        var callback = this.get('onDataPointDelete');
        callback(dataPoint);
      }
    }
  });
});