define('slate-payroll/components/bs-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    printing: Ember.inject.service(),
    classNames: ['modal', 'fade'],
    attributeBindings: ['id'],
    isPrinting: false,

    _toggleShow: Ember.observer('showModal', function () {
      var parentClass = this.get('elementId') + '-parent',
          inRoot = this.get('_inRoot'),
          showModal = this.get('showModal'),
          $this = this.$();

      var listenForAuxHide = this._hidViaModal.bind(this);

      if (showModal && !inRoot) {
        $this.after('<div class="' + parentClass + '"></div>');
        $this.appendTo('body');
        this.set('_inRoot', true);
        var $modal = this._bsFn(true);
        this.set('$modal', $modal);
        $modal.one('hidden.bs.modal', listenForAuxHide);
      } else if (!showModal && inRoot) {
        this.get('$modal').off('hidden.bs.modal', listenForAuxHide);

        if (this.get('hiddenByModal')) {
          this._manipulateDOM();
          this.set('hiddenByModal', null);
        } else {
          this._bsFn(false).one('hidden.bs.modal', Ember.run.bind(this, this._manipulateDOM));
        }
      }
    }),

    didInsertElement: function didInsertElement() {
      this._toggleShow();

      if (this.printable) {
        this._bindPrintingEvents();
      }
    },
    _bindPrintingEvents: function _bindPrintingEvents() {
      this.printing.set('currentModal', this);
      window.addEventListener('beforeprint', this.willPrint.bind(this));
      window.addEventListener('afterprint', this.donePrinting.bind(this));
    },
    _unbindPrintingEvents: function _unbindPrintingEvents() {
      this.printing.set('currentModal', null);
      window.removeEventListener('beforeprint', this.willPrint);
      window.removeEventListener('afterprint', this.donePrinting);
    },
    willPrint: function willPrint() {
      this.set('isPrinting', true);
      Ember.$('body').addClass('ember-application--printing-modal');
    },
    donePrinting: function donePrinting() {
      var _this = this;

      setTimeout(function () {
        if (!_this.isDestroyed) {
          _this.set('isPrinting', false);
        }

        Ember.$('body').removeClass('ember-application--printing-modal');
      }, 500);
    },
    _hidViaModal: function _hidViaModal() {
      this.setProperties({
        showModal: false,
        hiddenByModal: true
      });
    },
    _manipulateDOM: function _manipulateDOM() {
      var parentSelector = '.' + this.get('elementId') + '-parent';

      Ember.$(parentSelector).replaceWith(Ember.$('#' + this.get('elementId')));

      this.setProperties({
        _inRoot: false,
        hiddenByModal: false
      });

      if (this.get('onClose')) {
        this.get('onClose')();
      }
    },
    _bsFn: function _bsFn(showModal) {
      var $el = this.$();

      return showModal ? $el.modal(this.get('static') ? {
        keyboard: false,
        backdrop: 'static'
      } : 'show') : $el.modal('hide');
    },
    willDestroy: function willDestroy() {
      if (!this.get('_inRoot')) {
        return;
      }

      if (this.printable) {
        this._unbindPrintingEvents();
      }

      var elId = this.get('elementId'),
          parentClass = elId + '-parent';

      Ember.$('#' + elId).remove();
      Ember.$('.' + parentClass).remove();
    }
  });
});