define('slate-payroll/components/clock-indicator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ClockStatusComponent = Ember.Component.extend({
    tagName: 'span',
    classNames: ['employee__clock-indicator']
  });

  ClockStatusComponent.reopenClass({ positionalParams: ['status'] });

  exports.default = ClockStatusComponent;
});