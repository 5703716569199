define('slate-payroll/components/display/floating-header-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',

    classNames: ['table--floating-header'],
    classNameBindings: ['isFloating:table--is-floating'],

    didReceiveAttrs: function didReceiveAttrs() {
      window.addEventListener('scroll', this.onScroll.bind(this));
      window.addEventListener('touchMove', this.onScroll.bind(this));
    },
    willDestroy: function willDestroy() {
      window.removeEventListener('scroll', this.onScroll.bind(this));
      window.removeEventListener('touchMove', this.onScroll.bind(this));
    },
    onScroll: function onScroll(e) {
      Ember.run.throttle(this, 'handleScrollEvent', 50);
    },
    handleScrollEvent: function handleScrollEvent() {
      try {
        var navPad = document.querySelector('#slate__app-view > nav').offsetHeight,
            table = this.get('element');

        if (!table) {
          return;
        }

        var th = table.querySelector('thead'),
            thBoundingRect = th.getBoundingClientRect(),
            tableBoundingRect = table.getBoundingClientRect();

        if (tableBoundingRect.top - navPad > 0) {
          this.set('isFloating', false);
          th.style.transform = 'translateY(0)';
          return;
        }

        this.set('isFloating', true);

        var thHeight = thBoundingRect.height,
            scrollY = window.scrollY,
            transY = navPad + scrollY - (tableBoundingRect.top + scrollY);

        th.style.transform = 'translateY(' + Math.min(transY, tableBoundingRect.height - thHeight) + 'px)';
      } catch (err) {
        // noop
      }
    }
  });
});