define('slate-payroll/components/data-explorer/display-key-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.sortProperty = ['name'];
      this.valueChanged();
      this.sortedDisplayKeysChanged();
    },


    classNames: ['select-group', 'text-center', 'form-group'],

    sortedDisplayKeys: Ember.computed.sort('availableDisplayKeys', 'sortProperty'),

    valueChanged: Ember.observer('selectedKey.field', function () {
      if (!this.get('selectedKey.field')) {
        return;
      }
      return this.set('value', this.get('selectedKey.field'));
    }),

    availableDisplayKeys: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      modelFields.forEach(function (kind, field) {
        if (kind === 'attribute') {
          fields.addObject({
            field: field,
            name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
          });
        }
      });

      return fields;
    }),

    sortedDisplayKeysChanged: Ember.observer('availableDisplayKeys.[]', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var defaultKey = _this.get('sortedDisplayKeys').filter(function (key) {
          return key.field === _this.value;
        })[0] || _this.get('sortedDisplayKeys.firstObject');

        if (!_this.get('selectedKey') && defaultKey) {
          _this.set('selectedKey', defaultKey);
        }
      });
    })
  });
});