define('slate-payroll/components/close-audit-approval-item', ['exports', 'slate-payroll/mixins/confirm', 'slate-payroll/mixins/ux-ajax-status-hooks'], function (exports, _confirm, _uxAjaxStatusHooks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AuditApprovalItemComponent = Ember.Component.extend(_confirm.default, _uxAjaxStatusHooks.default, {
    store: Ember.inject.service(),
    tagName: '',

    actions: {
      rejectApproval: function rejectApproval() {
        var _this = this;

        if (this.get('working')) {
          return;
        }

        this.confirm('Rejecting a review will notify the supervisor to re-approve the pay period.', 'Reject Review').then(function (response) {
          if (!response) {
            return;
          }

          _this.ajaxStart();

          var approvalModel = _this.get('model.approval'),
              onChange = _this.get('onChange');

          approvalModel.setProperties({
            managerRejected: new Date(),
            managerApproval: null,
            quickApproval: null,
            completedOn: null
          });

          approvalModel.get('approved').setEach('at', null);

          approvalModel.save().then(function () {
            _this.ajaxSuccess('Successfully rejected approval');
            onChange();
          }).catch(_this.ajaxError.bind(_this));
        });
      },
      forceApproval: function forceApproval() {
        var _this2 = this;

        if (this.get('working')) {
          return;
        }

        this.confirm('Are you sure you want to force approval? Approving will block this supervisor & direct report employees from making further changes.', 'Force Approval', 'confirmStateForceApproval').then(function (response) {
          if (!response) {
            return;
          }

          _this2.ajaxStart();

          var approvalModel = _this2.get('model.approval');

          if (approvalModel.virt) {
            approvalModel = _this2.store.createRecord('approval', approvalModel);
            approvalModel.set('approved', _this2.get('model.subapprovals').map(function (subapproval) {
              return {
                at: null,
                employee: subapproval.employee.get('id')
              };
            }));
          }

          approvalModel.setProperties({
            managerApproval: true,
            managerRejected: null,
            quickApproval: null,
            completedOn: new Date()
          });

          approvalModel.save().then(function () {
            _this2.ajaxSuccess('Successfully forced approval');
            _this2.get('onChange')();
          }).catch(_this2.ajaxError.bind(_this2));
        });
      }
    }
  });

  AuditApprovalItemComponent.reopenClass({ positionalParams: ['model'] });

  exports.default = AuditApprovalItemComponent;
});