define('slate-payroll/components/data-explorer/display-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.sortProperty = ['name'];
    },


    toDisplay: Ember.A(),
    sortedAvailableFields: Ember.computed.sort('availableFields', 'sortProperty'),

    availableFields: Ember.computed('modelName', 'toDisplay.[]', function () {
      var _this = this;

      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelTransformedAttrs = Ember.get(Model, 'transformedAttributes'),
          modelFields = Ember.get(Model, 'fields'),
          toDisplay = this.get('toDisplay'),
          wageFields = ['payRateHourly', 'payRateSalary', 'payType', 'payRateVacation', 'bankAccounts', 'accrualSick'],
          excludeAllWages = this.auth.get('currentUser.excludeAllWages');

      // Result is an Ember.map from employee fields, so we have
      // to transform it to this object format

      modelFields.forEach(function (kind, field) {
        if (wageFields.includes(field) && excludeAllWages) {
          return;
        }

        var fieldData = {
          field: field,
          kind: kind,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' ')),
          isRelationship: kind !== 'attribute'
        };

        if (fieldData.isRelationship) {
          fieldData.relationshipModel = Ember.get(Model.typeForRelationship(field, _this.store), 'modelName');
        }

        fields.addObject(fieldData);
      });

      modelTransformedAttrs.forEach(function (kind, field) {
        var matchingField = fields.findBy('field', field);

        if (matchingField) {
          matchingField['is' + Ember.String.capitalize(kind)] = true;
          matchingField.type = kind;
        }
      });

      if (toDisplay && toDisplay.get('length') > 0) {
        toDisplay.forEach(function (displayField) {
          var displayModelName = _this.get('modelName'),
              displayModel = _this.get('store').modelFor(displayModelName),
              attributes = Ember.get(displayModel, 'attributes'),
              field = fields.findBy('name', displayField.get('propertyDisplayName'));

          displayField.relationshipModel = Ember.get(displayModel.typeForRelationship(displayField.propertyName, _this.store) || {}, 'modelName');

          attributes.forEach(function (kind, attribute) {
            if (attribute === displayField.propertyName) {
              Ember.set(displayField, 'is' + Ember.String.capitalize(kind.type), true);
              displayField.type = kind;
            }
          });

          if (field && !field.isRelationship) {
            fields.removeObject(field);
          }
        });
      }

      return fields;
    })
  });
});