define('slate-payroll/components/display-change-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['list-group-item', 'clearfix'],
    classNameBindings: ['detailed:list-group-item-primary'],
    attributeBindings: ['href'],
    href: '#',

    click: function click(ev) {
      ev.preventDefault();
      this.toggleProperty('detailed');
    }
  });
});