define('slate-payroll/components/bound-toggle', ['exports', 'slate-payroll/templates/components/bound-toggle'], function (exports, _boundToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _boundToggle.default,
    classNames: ['form-group', 'text-center', 'toggle-view'],
    attributeBindings: ['title'],
    theme: 'squish', // default theme

    inputId: Ember.computed('elementId', function () {
      return 'tglinput-' + this.get('elementId');
    }),

    toggleClass: Ember.computed('theme', function () {
      return 'toggle toggle-' + this.get('theme');
    })
  });
});