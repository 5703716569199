define('slate-payroll/components/data-explorer/limit-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    limitNumber: Ember.computed('limitItems', function () {
      return this.get('limitItems');
    }),

    limitByNumber: Ember.computed('limit', function () {
      return this.get('limitItems') || 0;
    }),

    creationDateDataPoint: Ember.computed('dataPoints.[]', function () {
      return this.get('dataPoints').findBy('limiter', true) || false;
    }),

    creationDateData: Ember.computed('creationDateDataPoint', function () {
      return !!this.creationDateDataPoint;
    }),

    updateValue: Ember.observer('limitNumber', 'limitCreationDate', 'creationDateData', 'limitByNumber', 'limitByCreationDate', function () {
      var dataPoints = this.get('dataPoints');

      if (this.get('limitByNumber') || this.limit) {
        this.set('limitItems', this.get('limitNumber'));
      }
      var dateDataPoint = this.get('dataPoints').findBy('limiter', true);

      if (!dateDataPoint && this.creationDateData) {
        dataPoints.addObject(this.store.createRecord('data-point', {
          operator: 'is within',
          value: this.get('creationDateDataPoint').value,
          isRelationship: false,
          isDate: true,
          propertyName: 'Created',
          propertyKey: 'created',
          propertyType: 'date',
          limiter: true
        }));
      }

      if (!this.creationDateData) {
        if (this.creationDateDataPoint.isNew) {
          this.creationDateDataPoint.destroyRecord();
        } else {
          dataPoints.removeObject(dateDataPoint);
        }
      }
    })
  });
});