define('slate-payroll/components/data-explorer/sort-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (this.get('sortField')) {
        var field = this.availableSortPoints.filter(function (point) {
          return point.field === _this.get('sortField');
        })[0];
        this.set('field', field);
        return;
      }
      this.set('field', this.availableSortPoints[0]);
    },


    availableSortPoints: Ember.computed('modelName', function () {
      var modelName = this.get('modelName');

      if (!modelName) {
        return Ember.A();
      }

      var fields = Ember.A(),
          Model = this.get('store').modelFor(modelName),
          modelFields = Ember.get(Model, 'fields');

      modelFields.forEach(function (kind, field) {
        var fieldData = {
          field: field,
          name: Ember.String.capitalize(Ember.String.decamelize(field).replace(/_/g, ' '))
        };

        fields.addObject(fieldData);
      });

      return fields;
    }),

    sortFields: Ember.computed('sortField', 'availableSortPoints.@each.field', function () {
      return this.availableSortPoints.findBy('field', this.get('sortField'));
    }),

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});