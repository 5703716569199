define('slate-payroll/components/display/support-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initialOpenTimeout();
    },


    classNames: ['support-link-component'],
    classNameBindings: ['open:open:closed'],
    initialOpen: true,
    openTimeout: 1,

    initialOpenTimeout: function initialOpenTimeout() {
      var _this = this;

      if (!this.get('initialOpen')) {
        return;
      }

      Ember.run.scheduleOnce('afterRender', function () {
        _this.set('open', true);

        Ember.run.later(function () {
          _this.set('open', false);
        }, _this.get('openTimeout') * 1000);
      });
    },

    actions: {
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      }
    }
  });
});