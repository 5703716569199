define('slate-payroll/components/data-explorer/data-point-value-field', ['exports', 'slate-payroll/config/models'], function (exports, _models) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultValues = ['$defined', '$undefined'],
      booleanSelectValues = [true, false].concat(defaultValues),
      multiBelongsTo = ['is one of', 'is not one of'];

  exports.default = Ember.Component.extend({
    booleanSelectValues: booleanSelectValues,
    tagName: 'span',
    dataPointValue: Ember.computed.reads('dataPoint.value'),
    isDateRange: Ember.computed.equal('dataPoint.type', 'date'),
    isBoolean: Ember.computed.reads('dataPoint.isBoolean'),
    isNumber: Ember.computed.reads('dataPoint.isNumber'),
    isString: Ember.computed.reads('dataPoint.isString'),
    isDate: Ember.computed.reads('dataPoint.isDate'),
    isRelationship: Ember.computed.reads('dataPoint.isRelationship'),
    multiRelationship: Ember.computed.equal('dataPoint.kind', 'hasMany'),
    operatorIsRegex: Ember.computed.equal('operator', '$regex'),

    multiModelSelect: Ember.computed('dataPoint.kind', 'operator', function () {
      return this.get('dataPoint.kind') === 'hasMany' || multiBelongsTo.indexOf(this.get('operator')) > -1;
    }),

    resetValue: Ember.observer('dataPoint', 'operator', function () {
      this.set('dataPointValue', null);
    }),

    fixtureSuggestions: Ember.computed('dataPoint.field', function () {
      var fieldKey = this.get('dataPoint.field');

      return defaultValues.map(function (v, i) {
        var obj = { _id: i + 1 };

        obj[fieldKey] = v;

        return obj;
      });
    }),

    lazyModelSettings: Ember.computed('isRelationship', 'dataPoint.relationshipModel', function () {
      var modelName = this.get('dataPoint.relationshipModel');

      if (this.get('isRelationship') && modelName) {
        var setting = _models.modelSettings[modelName];
        return setting || { label: 'id' };
      }
    }),

    dataPointValueChanged: Ember.observer('dataPointValue.[]', function () {
      var onchange = this.get('onchange');
      if (onchange && typeof onchange === 'function') {
        onchange(this.get('dataPointValue'));
      }
    })
  });
});