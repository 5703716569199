define('slate-payroll/components/close-audit-employee-item', ['exports', 'slate-payroll/utils/title-case'], function (exports, _titleCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sumOf(key, array) {
    return array.reduce(function (total, item) {
      var value = item[key];
      return value ? total + value : total;
    }, 0);
  }

  var AuditEmployeeItemComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    classNames: ['pay-period-close__audit-item'],
    classNameBindings: ['reviewed:pay-period-close__audit-item--reviewed'],
    tagName: 'tr',

    selected: Ember.computed('selection.[]', 'employee', function () {
      return this.get('selection').includes(this.get('employee'));
    }),

    totalString: Ember.computed('punches.@each.{hours,days,addEarnings,deduction,companyPaycode}', function () {
      var punches = this.get('punches') || [].filter(function (punch) {
        return !punch.companyPaycode.static;
      });

      var totals = [{ key: 'hours' }, { key: 'days' }, {
        key: 'addEarnings',
        display: 'additional earnings'
      }, {
        key: 'deduction',
        display: 'deductions'
      }];

      return totals.map(function (total) {
        return Object.assign(total, { sum: sumOf(total.key, punches) });
      }).filter(function (total) {
        return total.sum !== 0;
      }).map(function (total) {
        var display = total.display || total.key;
        return (0, _titleCase.default)([display]) + ': ' + total.sum.toFixed(2);
      }).join('<br />');
    }),

    actions: {
      selectedChanged: function selectedChanged() {
        var selected = this.$('input[type="checkbox"]').prop('checked');
        this.get('selection')[selected ? 'addObject' : 'removeObject'](this.get('employee'));
      }
    }
  });

  AuditEmployeeItemComponent.reopenClass({
    positionalParams: ['employee', 'payPeriod']
  });

  exports.default = AuditEmployeeItemComponent;
});