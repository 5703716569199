define('slate-payroll/components/data-explorer/query-build-edit', ['exports', 'slate-payroll/utils/title-case', 'slate-payroll/config/models', 'ember-inflector'], function (exports, _titleCase, _models, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);

  var QueryBuildEditComponent = Ember.Component.extend({

    availableQueryModels: _models.availableQueryModels.map(function (modelName) {
      return {
        modelName: modelName,
        title: (0, _titleCase.default)([inflector.pluralize(modelName).replace(/-/g, ' ')])
      };
    }),

    queryModelTitle: Ember.computed('query.queryModel', function () {
      return (0, _titleCase.default)([inflector.pluralize(this.get('query.queryModel')).replace(/-/g, ' ')]);
    }),

    actions: {
      addDataPoint: function addDataPoint(dataPoint) {
        this.get('query.dataPoints').addObject(dataPoint);
      },
      changeGroup: function changeGroup(query) {
        if (!query) {
          this.get('query.grouping').groupType = null;
          this.get('query.grouping').value = null;
          return;
        }
        var value = query.value,
            groupType = query.groupType;

        var grouping = this.get('query.grouping') || {};

        grouping.value = value;
        grouping.groupType = groupType;
      }
    }
  });

  exports.default = QueryBuildEditComponent;
});