define('slate-payroll/components/data-explorer/display-drop-zone', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    fields: Ember.A(),
    draggingFieldColumn: false,
    classNames: ['data-explorer-display-settings-drop-zone'],
    classNameBindings: ['dragHovering:drag-hovering'],

    dragEnter: function dragEnter(event) {
      event.preventDefault();
      if (!this.get('draggingFieldColumn')) {
        this.set('dragHovering', true);
        return false;
      }
    },
    dragLeave: function dragLeave(event) {
      event.preventDefault();
      if (!this.get('draggingFieldColumn')) {
        this.set('dragHovering', false);
      }
    },
    dragOver: function dragOver(event) {
      event.preventDefault();
      if (!this.get('draggingFieldColumn')) {
        this.set('dragHovering', true);
        return false;
      }
    },
    drop: function drop(event) {
      event.preventDefault();
      if (event.dataTransfer.getData('fieldColumn')) {
        return false;
      }

      this.set('dragHovering', false);

      var fieldIndex = event.dataTransfer.getData('fieldIndex'),
          availableFields = this.get('availableFields'),
          fields = this.get('fields'),
          field = availableFields.objectAt(fieldIndex);

      var displayPointRecord = this.store.createRecord('display-point', {
        propertyName: field.field,
        propertyDisplayName: field.name,
        _field: field
      });

      fields.addObject(displayPointRecord);

      return false;
    },


    actions: {
      moveField: function moveField(index, direction) {
        var fields = this.get('fields'),
            field = fields.objectAt(index),
            insertIndex = direction === 'up' ? index + 1 : index - 1;

        fields.removeObject(field).insertAt(insertIndex, field);
      },
      removeField: function removeField(field) {
        if (!field.id) {
          field.destroyRecord();
        }
        this.get('fields').removeObject(field);
      }
    }
  });
});