define('slate-payroll/components/census/dry-run-employee-relationship', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    relationship: Ember.computed('availableFields', 'key', 'value', function () {
      var key = this.get('key'),
          value = this.get('value'),
          field = this.get('availableFields').findBy('path', key) || {};

      if (field.isRelationship) {
        if (key === 'supervisor') {
          //change to employee because no supervisor model
          key = 'employee';
        }
        //if value is object, return value because its a supervisor in the local upload
        return (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' ? value : this.get('store').findRecord(key, value);
      }
    })
  });
});